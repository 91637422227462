@import "definitions";

body,html { 
    height:100%;
}

body { 
    min-height:100vh;
}

body > div:first-child { 
    margin:0;
    padding:0;
    width:100%;
    height:100%;
}

.view-container noscript {
    font-size:1.2em;
    font-weight: bold;
    color:#fff;
    background: darkred;
    text-align:center;
    padding:.5em;
    margin:1em 0;
    width:100%;
    display:block;
    box-sizing: border-box;
}

div.main_content {
    width:100%;
    position:absolute;
    top:3.5em;
    left:0;
    right:0;
    bottom:0;
}

div.main_nav.with_row_2 ~ div.main_content {
    top:7em;
}

div.easy_edit {
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

iframe#easy_edit {
    border:none;
    width:100%;
    height:100%;
}


/*Überschriften*/

h1, h2, h3, strong{
    font-family: $font_family_bold;
    font-weight: normal;
}

.heading{
    font-size: $font_size_heading;
}

.page_heading{
    border-bottom: $border;
    padding-bottom: $pad_small;
    margin: 0.7em 0 0;
    span{
        font-size: $font_size_heading;  
    }
    .arrow_up, .arrow_down{
        margin-left: $mar_small;
    }
}


.page_heading_box{
    margin-bottom: $mar;
}


/*
 * Basic left right guis
 */
div.split_layout{
    display:table;
    margin:0;
    min-height:100%;
    height: 100%;
    width:100%;
    margin-top: 2em;
}

div.sidebar{
    vertical-align: top;
    display:table-cell;
}

div.sidebar_inner{
    width: 20em;
}

div.split_layout_main{
    vertical-align: top;
    display:table-cell;
    width:100%;
    padding: 0 1.9em 2em 1.9em;
}

div.sidebar_box div.box_head i.fa {
    color: #aaaaaa;
}

div.page_heading_box div.actions_list {
    float: right;
}

/*weiße Bereiche - Hauptbereiche*/
.main_bg{
    background-color: $main_bg;  
    padding: $pad;
    border-top: $border;
    border-left: $border;
    margin: $mar 0;
    box-sizing: border-box;
    box-shadow: $main_box_shadow;
}


/*Tabellen*/
table {
    font-size: inherit;
    border-spacing: 0;
    border-collapse: collapse;
    th,td{
        text-align: left;
        white-space: nowrap;
    }
    th{
        font-family: $font_family_bold;
        white-space: nowrap;
        padding: 3px 5px;
    }
    td {
        padding: 1px 5px;
    }
}

div.loading_with_layer {

    &:not(.small){
        min-width: 5em;
        min-height: 5em;
    }

    position:relative;
    
    &:after {
        content: "";
        position: absolute;
        top:0;
        bottom:0;
        left:0;
        right:0;
        background-color: rgba(255,255,255, 0.5);
    }

    &:before {
        -webkit-animation: fa-spin 2s infinite linear;
        animation: fa-spin 2s infinite linear;
        font-family: "FontAwesome";
        font-size: 5em;
        color:#aaa;
        content: "\f110";
        position: absolute;
        left: 45%;
    }

    &.small:before{
        font-size: 2em;
    }

}